import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { ArrowPrevCircleIcon, ArrowNextCircleIcon } from "../Icons"

const NavPagination = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`
const PaginationList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
`
const PageLink = styled(Link)`
  position: relative;
  display: block;
  font-family: "Rajdhani", sans-serif;
  color: #6a6a6a;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  .icon {
    svg {
      fill: #cfcfcf;
    }
  }
  &:hover {
    color: #211f28;
    .icon {
      svg {
        fill: #211f28;
      }
    }
  }
`

const PaginationItem = styled.li`
  margin: 0 15px;
  .icon,
  .text {
    display: inline-block;
    vertical-align: middle;
  }
  &.disabled {
    .icon {
      svg {
        fill: #cfcfcf;
      }
    }
    .text {
      color: #6a6a6a;
    }
  }
  .icon {
    + .text {
      margin-left: 15px;
    }
  }
  .text {
    color: #6a6a6a;
    + .icon {
      margin-left: 15px;
    }
  }

  &.active {
    color: #211f28;
    font-weight: bold;
  }
`

const BlogPager = ({ pageContext, locale }) => {
  const { previousPagePath, nextPagePath } = pageContext
  return (
    <NavPagination className="pagination-nav">
      <PaginationList>
        <PaginationItem className="prev">
          {previousPagePath && (
            <PageLink to={`/${locale}${previousPagePath}`}>
              <span className="icon">
                <ArrowPrevCircleIcon />
              </span>
              <span className="text">Prev</span>
            </PageLink>
          )}
        </PaginationItem>
        <PaginationItem className="page-number">
          {pageContext.humanPageNumber - 1 > 1 ? <>...</> : <></>}
        </PaginationItem>
        <PaginationItem className="prev">
          {previousPagePath ? (
            <PageLink to={`/${locale}${previousPagePath}`}>
              <span className="text">
                {pageContext.humanPageNumber < 10 ? (
                  <>0{pageContext.humanPageNumber - 1}</>
                ) : (
                  <>{pageContext.humanPageNumber - 1}</>
                )}
              </span>
            </PageLink>
          ) : (
            <></>
          )}
        </PaginationItem>
        <PaginationItem className="active">
          {pageContext.humanPageNumber < 10 ? (
            <>0{pageContext.humanPageNumber}</>
          ) : (
            <>{pageContext.humanPageNumber}</>
          )}
        </PaginationItem>
        <PaginationItem className="next">
          {nextPagePath &&
          pageContext.humanPageNumber <
            Math.ceil(pageContext.numberOfPages / 2) ? (
            <PageLink to={`/${locale}${nextPagePath}`}>
              <span className="text">
                {pageContext.humanPageNumber < 10 ? (
                  <>0{pageContext.humanPageNumber + 1}</>
                ) : (
                  <>{pageContext.humanPageNumber + 1}</>
                )}
              </span>
            </PageLink>
          ) : (
            <></>
          )}
        </PaginationItem>
        <PaginationItem className="page-number">
          {pageContext.humanPageNumber + 1 <
          Math.ceil(pageContext.numberOfPages / 2) ? (
            <>...</>
          ) : (
            <></>
          )}
        </PaginationItem>
        <PaginationItem className="next">
          {pageContext.humanPageNumber <
          Math.ceil(pageContext.numberOfPages / 2) ? (
            <PageLink to={`/${locale}${nextPagePath}`}>
              <span className="text">Next</span>
              <span className="icon">
                <ArrowNextCircleIcon />
              </span>
            </PageLink>
          ) : (
            <></>
          )}
        </PaginationItem>
      </PaginationList>
    </NavPagination>
  )
}

export default BlogPager
